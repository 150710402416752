import * as React from "react"
import { graphql } from "gatsby"
import { withPreview } from "gatsby-source-prismic"
import { RichText } from "prismic-reactjs"

import Layout from "../components/layout"
import SEO from "../components/seo"
import htmlSerializer from "../components/htmlSerializer"
import AdSlot from "../components/shared/adSlot"

const Page = ({ data }) => {
    if (!data) return null
    const document = data.prismicPage.data
    const settings = data.prismicGeneralSettings.data

    return (
        <Layout>
            <SEO
                title={
                    document.seo_title.text ||
                    RichText.asText(document.page_title.raw)
                }
                description={document.seo_description?.text}
                ogImage={document.og_image?.url}
                robots={document.meta_robots.text}
            />
            <div className="bg-gray-100">
                {/* Other Slot */}
                <AdSlot html={settings.other_slot?.text} />
            </div>
            <div className="grid md:grid-cols-3 gap-16 mx-auto px-6 pt-12 max-w-7xl">
                <div className="md:col-span-2">
                    <div className="mb-4">
                        <h1 className="text-3xl md:text-4xl font-light text-gray-700 leading-snug md:leading-tight">
                            {RichText.asText(document.page_title.raw)}
                        </h1>
                    </div>
                    <div className="pb-20 prose prose-lg max-w-none text-gray-600">
                        <RichText
                            render={document.page_content.raw}
                            htmlSerializer={htmlSerializer}
                        />
                    </div>
                </div>
                <div>
                    {/* Sidebar Slot */}
                    <AdSlot html={document.page_sidebar_slot?.text} />
                </div>
            </div>
        </Layout>
    )
}

export default withPreview(Page)

export const query = graphql`
    query PageQuery($uid: String!) {
        prismicGeneralSettings {
            data {
                other_slot {
                    text
                }
            }
        }
        prismicPage(uid: { eq: $uid }) {
            data {
                page_title {
                    raw
                }
                seo_title {
                    text
                }
                seo_description {
                    text
                }
                meta_robots {
                    text
                }
                og_image {
                    url
                }
                page_content {
                    raw
                }
                page_sidebar_slot {
                    text
                }
            }
        }
    }
`
