import React from "react"
import { Elements } from "prismic-richtext"
import { Link as PrismicLink } from "prismic-reactjs"
import { Link } from "gatsby"
import { linkResolver } from "../utils/linkResolver"

const propsWithUniqueKey = function (props, key) {
    return Object.assign(props || {}, { key })
}

const htmlSerializer = function (type, element, content, children, key) {
    var props = {}

    switch (type) {
        case Elements.preformatted: // Preformatted
            const html = React.createElement("div", {
                dangerouslySetInnerHTML: { __html: element.text },
            })
            return React.createElement(
                "div",
                propsWithUniqueKey(props, key),
                html
            )

        case Elements.hyperlink:
            let result = ""
            const url = PrismicLink.url(element.data, linkResolver)
            if (element.data.link_type === "Document") {
                result = (
                    <Link to={url} key={key}>
                        {content}
                    </Link>
                )
            } else {
                const targetAttr = element.data.target
                    ? { target: element.data.target }
                    : {}
                const relAttr = element.data.target ? { rel: "noopener" } : {}
                props = Object.assign(
                    {
                        href: element.data.url || linkResolver(element.data),
                    },
                    targetAttr,
                    relAttr
                )
                result = React.createElement(
                    "a",
                    propsWithUniqueKey(props, key),
                    children
                )
            }
            return result

        default:
            return null
    }
}

export default htmlSerializer
